<template>
  <div
    v-if="question"
    id="question"
  >
    <div v-if="question.content" class="border-bottom my-2 bg-white">
      <div class="border rounded p-4 text-left">
        <h5>질문 내용</h5>
        <div
          v-if="question.regDate"
          class="text-right"
        >
          <div>
            <b-badge>
              {{ new Date(question.regDate).toLocaleString() }}
            </b-badge>
          </div>
          <div>
            <b-badge>
              {{ question.regUserName }}
            </b-badge>
          </div>
        </div>
        <hr/>
        <p
            v-html="question.content"
        />
        <hr/>
        <div v-if="question.file">
          <a download :href="question.file" target="_blank">
            <small>
              {{ question.file }}
            </small>
          </a>
        </div>
      </div>
    </div>
    <div>
      <div class="editor p-1">
        <div class="text-right">
          <p style="font-size:0.8rem;">
            {{ answerDate }}
          </p>
        </div>
        <editor
            :api-key="API_KEY"
            :init="EDITOR_OPTIONS"
            v-model="question.answerContent"
            @onInit="editorReady"
        />
      </div>
      <div>
        <b-button block squared :disabled="pending" @click="save">
          <font-awesome-icon icon="save"/>
        </b-button>
      </div>
    </div>

  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  name: "answerContent",
  components: {
    Editor,
    // EditorMenuBar
  },
  data: () => ({
    _id: null,
    pending: false,
    question: {
      _id: null,
      subject: null,
      agree: null,
      answer: null,
      mobile: null,
      email: null,
      content: null,
      file: null,
      regDate: null,
      regUser: null,
      regUserName: null,
      answerContent: null,
      answerDate: null,
      answerDuration: null,
      answerUser: null,
      answerUserName: null
    },
    editor: null,
    message: null
  }),
  computed: {
    answerDate () {
      const { answerDate } = this.question
      return answerDate ? new Date(answerDate).toLocaleString() : `답변 전`
    }
  },
  mounted () {
  },
  beforeDestroy () {
  },
  created () {
    (async () => {
      const {_id} = this.$route.params
      if (_id) {
        this._id = _id
        await this.load()
      }
    }) ()
  },
  methods: {
    async load () {
      try {
        const {data} = await this.axios({
          url: `/support/question/${this._id}`,
        })
        const {result, question} = data
        if (result) this.question = question
      } catch (e) {
      }
    },
    async save () {
      if (!confirm(`답변 등록을 하시겠습니까?`)) return
      this.pending = true
      try {
        const { answerContent } = this.question
        const {data} = await this.axios({
          url: `/admin/answer/${this._id}`,
          method: 'PATCH',
          data: {
            answerContent
          }
        })
        const { result, question, error } = data
        if (result && question) {
          this.question = question
          this.$emit('load')
        }
        this.$bvToast.toast(result ? `답변되었습니다.` : `${error}`, {
          title: result ? `SUCCESS` : 'ERROR',
          autoHideDelay: 5000,
          variant: result ? `success` : 'danger',
          appendToast: false
        })
      } catch (e) {
      }
      this.pending = false
    },
    editorReady () {
    }
  }
}
</script>

<style scoped>
  #question {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
  }
  .editor {
    min-height: 400px;
  }
</style>
